/*==================== SOLUTIONS ====================*/
.solutions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    position: relative;
    padding: 80px 5%;
  }
  .solutions-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 17px;
    }
    .solutions-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;

    }
    .solutions-row {
      display: flex;
      align-items: flex-start;
      gap: 15px;
    }
    .solutions-row-card {
      border-radius: 24.741px;
      border: 4.8px solid rgba(40, 34, 63, 0.43);
      background: linear-gradient(357deg, rgba(3, 0, 20, 0.35) 61.62%, rgba(32, 30, 39, 0.35) 126.43%);
      backdrop-filter: blur(19.201183319091797px);
      padding: 41px 0 0 0;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    justify-content: space-between;
    gap: 15px;
    }
    .solutions-row-card-left {
      width: 60%;
    }
    .solutions-row-card-right {
      width: 40%;
    }
    .solutions-row-card-header {
      display: flex;
padding-bottom: 0.41px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 9.49px;
padding-left: 23px;
    }
    .solutions-row-card-title {
      color: var(--white, #FFF);
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 28.8px; /* 120% */
letter-spacing: -0.5px;

    }
    .solutions-row-card-subtitle {
      color: var(--white, #FFF);
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 22.4px; /* 149.333% */
      max-width: 390px;
    }
    .solutions-row-card-img {
      margin-left: auto;
      margin-right: auto;
      width: 95%;
    }
    .solutions-row-card-img-2 {
      width: 100%;
    }
    .solutions-row-card-img-4 {
      margin-right: auto;
      width: 95%;
    }

  

    
  @media screen and (max-width: 767px) {
    .solutions-row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }
    .solutions-row-card-left {
      width: 100%;
    }
    .solutions-row-card-right {
      width: 100%;
    }
    
  }

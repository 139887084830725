
.wallets-box {
  border-radius: 8px;
  border: 0.6px solid var(--Secondary-Colors-Color-4, #343B4F);
  background: var(--Secondary-Colors-Color-1, #0B1739);
  padding: 0 20px 20px; /* Adjust padding to include header space */
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 300px; /* Fixed height */
  overflow-y: auto; /* Scrollable content */
  position: relative; /* Needed for sticky positioning */
}

.wallet-summary-count {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.wallet-summary-title {
  color: #AEB9E1;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  gap: 4px;
  margin: 2vh 0;
}

.wallet-count {
  color: var(--Neutral-Colors-100, #FFF);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "DM Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
}

.wallet-growth {
  display: flex;
  padding: 2px 4px;
  align-items: center;
  gap: 5px;
  border-radius: 2px;
  border: 0.6px solid;
  background: rgba(5, 193, 104, 0.20);
  color: #14CA74;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 140% */
}

.wallet-growth-positive {
  border-color: rgba(5, 193, 104, 0.20);
  background: rgba(5, 193, 104, 0.20);
  color: #14CA74;
}

.wallet-growth-negative {
  border-color: rgba(255, 0, 0, 0.20);
  background: rgba(255, 0, 0, 0.20);
  color: #FF0000;
}

.wallet-growth-null {
  border-color: rgba(255, 255, 0, 0.20);
  background: rgba(255, 255, 0, 0.20);
  color: #FFFF00;
}

.pricing-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
}
.pricing-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;
}
.pricing-content {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}
.pricing-card {
  display: flex;
  padding: 20px 31px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  border-radius: 10px;
  border: 1px solid #201f30;
  background: rgba(3, 0, 20, 0.4);
  backdrop-filter: blur(27.5px);
}
.pricing-card-title {
  color: #f0f0f1;
  font-family: "DM Sans";
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.pricing-card-price {
  display: flex;
  align-items: center;
  gap: 5px;
}
.pricing-card-price-img {
  display: flex;
  width: 27.5px;
  height: 27.923px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pricing-card-price-number {
  color: var(--Neutrals-neutrals-6, #c0bfc4);

  /* typography/desktop-h5 */
  font-family: "DM Sans";
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.pricing-card-price-text {
  color: var(--Neutrals-neutrals-8, #5b5966);

  /* typography/desktop-p */
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.plan-getstarted {
  width: 100%;
  justify-content: center;
}
.pricing-card-content {
  display: flex;
  height: 145px;
  flex-direction: column;
  align-items: flex-start;
}
.pricing-card-text {
  color: #fff;
  font-family: "DM Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.pricing-card-row {
  display: flex;
  height: 32px;
  padding: 4px 8px;
  align-items: center;
  gap: 7px;
  flex-shrink: 0;
}
.pricing-card-row-img {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
}
.pricing-card-row-text {
  color: var(--Neutrals-neutrals-6, #c0bfc4);
  font-family: "DM Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.585px;
}

@media screen and (max-width: 767px) {
  .pricing-content {
    flex-direction: column;
    gap: 25px;
    align-items: center;
  }
  .pricing-card {
    width: 100%;
  }
}

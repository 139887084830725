.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 15px;
  padding: 40px 0;
}
.carousel {
  overflow: hidden;
  width: 100%;
}
.carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
}
.carousel-item {
  min-width: 400px;
  color: var(--black, #0F1014);
  height: 315px;
  margin-left: 15px;
  margin-right: 15px;
  transition: transform 0.5s ease-in-out;
  background-color: var(--white);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 15px;
}
.carousel-item-img-box {
  height: 160px;
  overflow: hidden;
}
.carousel-item-img {
  border-radius: 20px 20px 0 0;
  flex-grow: 1;
  background-size: cover;
  background-position: center;
}
.carousel-item-box {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.carousel-item-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.carousel-item-text {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.carousel-item-button {
  display: flex;
  padding: 5px 10px;
  align-items: flex-start;
  border-radius: 20px;
  background: var(--black, #0F1014);
  color: var(--white, #FFF);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: auto;
  gap: 10px;
}
.carousel-title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 8%;
  padding-bottom: 30px;
}
.carousel-title {
  color: var(--white);
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.carousel-subtitle {
  color: var(--white);
  font-size: 15.065px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 8%;
}
.agency-carrousel-buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  align-items: center;
  color: var(--black);
  gap: 5px;
}
.arrow {
  background: var(--black);
  border: none;
  color: var(--black);
  font-size: 20px;
  padding: 5px 6px 5px 6px;
  cursor: pointer;
  border-radius: 80.074px;
}
.arrow:hover {
  background: var(--not-selected);
  color: var(--white);
}
@media only screen and (max-width: 767px) {
  .carousel-title-box {
      flex-direction: column;
      padding: 0 1.5%;
      padding-bottom: 30px;
  }
  .carousel-container {
      align-items: center;
  }
  .carousel-item {
      min-width: 300px;
  }
  .carousel-title {
      text-align: center;
  }
}

/* Main container for the entire management section */
.waitlist-management {
  padding: 20px;
  min-height: 100vh;
  font-family: "DM Sans", sans-serif;
}

.waitlist-management-header{
  display: flex;
      align-items: center;
      justify-content: flex-start;  
      gap:35%
}

/* Page title */
.waitlist-management h1 {
  margin-bottom: 20px;
  font-size: 28px;
  text-align: center;
}

/* Layout: left panel (user list) and right panel (analytics) */
.management-container {
  display: flex;
  gap: 20px;
}

/* Left panel: user list */
/* Left panel: user list */
.user-list {
  flex: 3;
  border: 0.6px solid var(--Secondary-Colors-Color-4, #343B4F);
  background: var(--Secondary-Colors-Color-1, #0B1739);
  border-radius: 8px;
  padding: 20px;
  overflow-y: auto;
  max-width: 500px;
  height: 80vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
  /* Break words that are too long */
  overflow-wrap: break-word;
  /* Ensures words break to the next line */
  white-space: normal;
  /* Allow wrapping of content */
}


.user-list h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.user-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.user-list li {
  padding: 10px 0;
  border-bottom: 1px solid var(--Secondary-Colors-Color-4, #343B4F);
  word-wrap: break-word;
  /* Ensures the text inside li also wraps */
  overflow-wrap: break-word;
}

.user-list li strong {
  font-size: 16px;
  display: inline-block;
  max-width: 100%;
  /* Prevent overflow */
  white-space: nowrap;
  /* Prevent text from wrapping */
  overflow: hidden;
  /* Hide overflow */
  text-overflow: ellipsis;
  /* Add "..." for truncated text */
}

.user-list li p {
  font-size: 14px;
  margin: 5px 0;
}

.user-list label {
  display: flex;
  justify-content: space-between;
}

/* Right panel: analytics and actions */
.analytics-panel {
  flex: 2;
  /* Takes up less space */
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* Two columns */
  gap: 20px;
}

/* Individual analytics box */
.analytics-box {
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 0.6px solid var(--Secondary-Colors-Color-4, #343B4F);
  background: var(--Secondary-Colors-Color-1, #0B1739);
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.analytics-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.analytics-box h3 {
  font-size: 18px;
  margin-bottom: 15px;
}

.analytics-box p {
  font-size: 24px;
  font-weight: bold;
}

.analytics-box button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}




/* Filters Section */
.filters {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  align-items: center
}

/* User List */
.user-list ul {
  max-height: 70vh;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 0;
}

.user-list li {
  margin-bottom: 20px;
  padding-bottom: 10px;
  max-width: 80%;
}

/* Dropdown filter container */
.filter-dropdown {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.filter-dropdown label {
  font-size: 14px;
  color: #34495e;
}

.filter-dropdown select {
  padding: 5px;
  cursor: pointer;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 0.6px solid var(--Neutral-Colors-600, #0B1739);
  background: var(--Neutral-Colors-700, #0A1330);
  box-shadow: 1px 1px 1px 0px rgba(16, 25, 52, 0.40);
  color: var(--Neutral-Colors-400, #AEB9E1);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  min-width: 120px;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  border: 0.6px solid var(--Neutral-Colors-600, #0B1739);
  background: var(--Neutral-Colors-700, #0A1330);
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  max-height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.modal label {
  display: flex;
  gap: 25px;
}

.close-button {
  background: red;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.send-email-button {
  background: #007bff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.send-email-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}
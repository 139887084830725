.separator {
    border-radius: 25px;
    border: 1px solid #4D2F8C;
    padding: 7px 15px;
    box-shadow: 0px -7px 11px 0px rgba(164, 143, 255, 0.12) inset;
    background: rgba(255, 255, 255, 0.00);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.Waitlistpage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10%;
    text-align: center;
    background: var(--bg, #030014);
}

.header-waitlist {
    margin-top: 80px;

    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 17px;
}

.header-waitlist h1 {
    color: #B6B2FF;
    font-size: 65px;
    font-family: "DM Sans";
    font-weight: 700;
    text-transform: capitalize;
}

.header-waitlist p {
    color: #FFF;
    font-family: Inter;
    font-weight: 500;
    font-size: 15px;
    line-height: 22.4px;
    max-width: 675px;
}

.waitlist-input {
    display: flex;
    padding-top: 30px;
    gap: 10px;
    width: 70%;
    align-items: stretch;
    flex-direction: column;
}

.waitlist-subscription {
    display: flex;
}

.waitlist-input input {
    display: flex;
    padding: 14px 0px 14.116px 11px;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
    width: 80%;
    border-radius: 9px 0px 0px 9px;
    border: 1px solid #24292E;
    background: #1A1D21;
    color: #F4F0FF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-height: 50px;
}

.waitlist-input button {
    display: flex;
    width: 190px;
    padding: 9.676px 22.337px ;
    justify-content: center;
    align-items: center;
    border-radius: 0px 9px 9px 0px;
    border: 1.382px solid #4D2F8C;
    color: #F4F0FF;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 27.645px;
    background: linear-gradient(180deg, rgba(60, 8, 126, 0.00) 0%, rgba(60, 8, 126, 0.32) 100%), rgba(113, 47, 255, 0.12);
    box-shadow: 0px 0px 16.587px 0px rgba(191, 151, 255, 0.24) inset;
    max-height: 50px;

}

.waitlist-input p {
    color: #ACB3B9;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.64px;
}

.waitlist-input span {
    color: #F4F0FF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.64px;
    text-decoration-line: underline;
}

.waitlist-features {
    margin-top: 80px;

    display: inline-flex;
    gap: 17px;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 5;
}

.waitlist-elipse {
    position: absolute;
    width: 618px;
    height: 529px;
    flex-shrink: 0;
    border-radius: 618px;
    opacity: 0.23;
    background: var(--primary-purple-primary-purple-700, #5000B5);
    filter: blur(75px);
}

/* Default layout: Desktop - 3 cards in a row */
.features {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 3 equal columns */
    gap: 25px;
    /* Gap between cards */
    justify-items: center;
    /* Center cards in their grid area */
    margin-top: 15px;
}

.feature-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px 45px 20px;
    gap: 25px;
    border-radius: 24.741px;
    border: 4.8px solid rgba(40, 34, 63, 0.43);
    background: linear-gradient(357deg, rgba(3, 0, 20, 0.35) 61.62%, rgba(32, 30, 39, 0.35) 126.43%);
    backdrop-filter: blur(19.2px);
    width: 100%;
    /* Ensure cards fill their grid area */
}

.waitlist-features h2 {
    color: var(--washed-purple-washed-purple-500, var(--purple-light, #B6B2FF));
    text-align: center;
    font-family: "DM Sans";
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    z-index: 5;
    max-width: 750px;
}

.waitlist-features p {
    color: var(--washed-purple-washed-purple-700, #817EB5);
    text-align: center;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    z-index: 5;
    max-width: 750px;

}

.feature-card h3 {
    color: var(--white, #FFF);
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28.8px;
    letter-spacing: -0.5px;
}

.feature-card p {
    color: var(--white, #FFF);
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.4px;
}

.feature-card i {
    width: 62.5px;
    height: 50px;
    color: var(--purple-light, #B6B2FF);
    font-weight: 400;
}

.waitlist-faq-waitlist {
    z-index: 5;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    position: relative;
    max-width: 750px;
    margin-bottom: 60px;
}

.faq-waitlist-head {
    margin-top: 80px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 17px;
    z-index: 5;
}

.faq-waitlist-head h2 {
    color: var(--washed-purple-washed-purple-500, var(--purple-light, #B6B2FF));
    text-align: center;
    font-family: "DM Sans";
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.faq-waitlist-head p {
    color: var(--washed-purple-washed-purple-700, #817EB5);
    text-align: center;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.waitlist-faq-list {
    z-index: 5;
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: stretch;
    gap: 22px;
}

.waitlist-faq-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 22px;
    font-family: "DM Sans";

}

.waitlist-faq-question {
    display: flex;
    width: 90%;

    min-width: 40vh;
    padding: 10px 35px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #201F30;
    background: linear-gradient(180deg, #030014 0%, #282637 100%);
}

.waitlist-faq-answer {
    color: var(--washed-purple-washed-purple-700, #817EB5);
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.waitlist-faq-elipse {
    position: absolute;
    width: 618px;
    height: 529px;
    flex-shrink: 0;
    border-radius: 618px;
    opacity: 0.23;
    background: var(--primary-purple-primary-purple-700, #5000B5);
    filter: blur(75px);
    z-index: 2;
    top: 10%;
}

.waitlist-toggle-btn {
    background-color: transparent;
    border: none;
    color: rgba(255, 255, 255, 1);
    font-size: 35px;
    flex-shrink: 0;
}

.waitlist-input .waitlist-error {
    color: #FF4747;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: -0.64px;
}

.waitlist-input .waitlist-success {
    color: var(--white, #FFF);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: -0.64px;
}

/* Tablet layout: 2/1 (2 cards on top, 1 card centered below) */
@media (min-width: 576px) and (max-width: 1024px) {
    .features {
        grid-template-columns: repeat(2, 1fr);
        /* 2 equal columns */
        grid-template-rows: auto auto;
        /* Allow rows to adapt to content */
        gap: 25px;
    }
    .waitlist-input button{
        font-size: 20px;
        padding: 0%;
    }
    .features .feature-card:nth-child(1),
    .features .feature-card:nth-child(2) {
        grid-column: span 1;
        /* First two cards in separate columns */
    }

    .features .feature-card:nth-child(3) {
        grid-column: 1 / -1;
        /* Third card spans the entire width */
        justify-self: center;
        /* Center the third card */
    }
}


/* Responsive for Phones (max-width: 576px) */
@media (max-width: 576px) {
    .Waitlistpage {

        padding: 25px 0;
        margin: 5%;
    }

    .waitlist-features h2 {
        font-size: 30px;
        word-wrap: break-word;
        /* Ensure the content wraps */
        white-space: normal;
        /* Ensure the text wraps normally */
        width: 100%;
    }

    .waitlist-features p {
        font-size: 16px;
    }
    .waitlist-input button{
        font-size: 18px;
        padding: 0%;
    }
    .features {
        grid-template-columns: 1fr;
        /* 1 column */
        grid-template-rows: auto;
        /* Stack each card on top of each other */
        gap: 15px;
    }

    .feature-card {
        width: 100%;
        /* Ensure full-width cards */
    }

    .header-waitlist{
        margin-top: 40px;
    }

    .header-waitlist h1 {
        font-size: 45px;
        /* Smaller title for phones */
    }

    .header-waitlist p {
        font-size: 14px;
        padding: 0 10px;
        /* Padding to prevent text overflow */
    }

    .waitlist-input {
        width: auto;
        padding: 0;
    }


    .faq-waitlist {
        word-wrap: break-word;
        /* Ensure the content wraps */
        white-space: normal;
        /* Ensure the text wraps normally */
        width: 100%;
    }

    .faq-waitlist-head h2 {
        font-size: 30px;
    }

    .faq-waitlist-head p {
        font-size: 16px;
    }
    .faq-list {
        width: 80%;
    }

    .faq-question {
        width: 80%;
        /* Full width FAQ */
        padding: 15px;
        /* Smaller padding */
    }

    .faq-answer {
        font-size: 14px;
        /* Smaller font for FAQ answers */
    }

    .waitlist-elipse,
    .waitlist-faq-elipse {
        width: 300px;
        /* Reduce size */
        height: 250px;
        top: 5%;
        /* Adjust position */
    }
}
/* DashboardAdmin.css */

.admin-dashboard {
  padding: 4% 16.5% 0 16.5%;
  background-color: #081028;
  min-height: 100vh;
  position: relative;
  font-family: "DM Sans";

}

.loading-overlay {
  height: 100%;
  width: 100%;
  background-color: rgb(21 23 28 / 74%);
  position: absolute;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  position: absolute;
  width: 100px;
  height: 100px;
  border: 10px solid #f3f3f33f; /* Light grey */
  border-top: 10px solid #3823c5; /* Blue */
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

.static-image {
  width: 50px; /* Adjust size as needed */
  z-index: 1; /* Ensure it stays above the spinner */
  margin-top: -1vh;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Disable scrolling */
.no-scroll {
  overflow: hidden;
}

.center {
  align-items: center;
}

.admin-dashboard h1,
.admin-dashboard h2 {
  color: var(--Neutral-Colors-100, #FFF);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.admin-dashboard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 47px 0;
}

.filter-container {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.user-type-dropdown {
  display: inline-flex;
  padding: 9px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  border: 0.6px solid var(--Neutral-Colors-600, #0B1739);
  background: var(--Neutral-Colors-700, #0A1330);
  box-shadow: 1px 1px 1px 0px rgba(16, 25, 52, 0.40);
}

.date-picker label,
.date-option-dropdown label,
.user-type-dropdown label {
  margin-bottom: 5px;
  font-weight: bold;
}

.admin-dashboard-card-container {
  display: grid;
  gap: 16px;
}

.admin-dashboard-card-container2 {
  display: grid;
  gap: 16px;
}

.admin-dashboard-content {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 7vh;
  align-items: flex-start


}

.SSS-logo-admin-dashboard {
  width: 30%;
  margin-left: 35%;
}

/* Responsive grid */
@media (max-width: 930px) {
  .admin-dashboard-card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 930px) and (max-width: 1380px) {
  .admin-dashboard-card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1380px) {
  .admin-dashboard-card-container {
    grid-template-columns: repeat(4, 1fr);
  }
}



.newsletter-component {
  width: 98%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 600px;
}
.newsletter-input {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.05);
}
.newsletter-input-field {
  display: flex;
  height: 44px;
  padding: 9.676px 10px 10px 10px;
  align-items: center;
  gap: 26px;
  border-radius: 11.058px 0px 0px 11.058px;
  border-top: 1.382px solid #4d2f8c;
  border-bottom: 1.382px solid #4d2f8c;
  border-left: 1.382px solid #4d2f8c;
  background-color: transparent;
  border-right: none;
  width: 100%;
  color: #f4f0ff;
}
.newsletter-input-field::placeholder {
  color: #817eb5;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 27.645px; /* 230.372% */
}
.newsletter-input-btn {
  border-radius: 11.058px;
  background: linear-gradient(
      180deg,
      rgba(60, 8, 126, 0) 0%,
      rgba(60, 8, 126, 0.32) 100%
    ),
    rgba(113, 47, 255, 0.12);
  box-shadow: 0px 0px 16.587px 0px rgba(191, 151, 255, 0.24) inset;
  border: 1.382px solid #4d2f8c;
  display: flex;
  width: 110px;
  height: 44px;
  padding: 9.676px 10px 10px 10px;
  justify-content: center;
  align-items: center;
  gap: 26px;
  color: #f4f0ff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 27.645px; /* 172.779% */
  margin-left: -10px;
}
.newsletter-input-btn:hover {
  background: linear-gradient(
      180deg,
      rgba(60, 8, 126, 0) 0%,
      rgb(118 79 167 / 32%) 100%
    ),
    rgba(113, 47, 255, 0.12);
  transition: 0.3s ease;
  cursor: pointer;
}
.newsletter-input-btn-disabled {
  border-radius: 11.058px;
  background: linear-gradient(
      180deg,
      rgba(115, 115, 115, 0) 0%,
      rgba(136, 133, 139, 0.32) 100%
    ),
    rgba(241, 241, 241, 0.12);
  box-shadow: 0px 0px 16.587px 0px rgba(191, 151, 255, 0.24) inset;
  border: 1.382px solid #4d2f8c;
  border: 1.382px solid #676767;
  display: flex;
  width: 110px;
  height: 44px;
  padding: 9.676px 10px 10px 10px;
  justify-content: center;
  align-items: center;
  gap: 26px;
  color: #f4f0ff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 27.645px; /* 172.779% */
  margin-left: -10px;
}
.newsletter-component-error-msg,
.newsletter-component-send-msg {
  color: #f4f0ff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-left: 5px;
}

/* STYLE FOR EXTRA */
.newsletter-top-separator {
    width: 67.067px;
height: 2px;
background: #F0F0F1;
}
.newsletter-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}
.newsletter-section-title {
    color: var(--Neutrals-neutrals-4, #F0F0F1);
    text-align: center;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.newsletter-section-text {
    color: var(--Neutrals-neutrals-4, #F0F0F1);
text-align: center;
font-family: Inter;
font-size: 11px;
font-style: normal;
font-weight: 300;
line-height: normal;

}
.newsletter-section-text a {
    color: var(--Neutrals-neutrals-4, #F0F0F1);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: normal;
text-decoration-line: underline;
}

footer .newsletter-section-title {
    text-align: left;
}
footer .newsletter-section {
    align-items: flex-start;
    width: 50%;
    max-width: 400px;
}

@media screen and (max-width: 767px) {
    footer .newsletter-section-title {
        text-align: center;
    }
    footer .newsletter-section {
        align-items: center;
        width: 90%;
        max-width: 400px;
        margin: auto;
    }
}
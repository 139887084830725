  
  .subscription-box {
    border-radius: 8px;
    border: 0.6px solid var(--Secondary-Colors-Color-4, #343B4F);
    background: var(--Secondary-Colors-Color-1, #0B1739);
    padding: 0 20px 20px;
    width: 300px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 300px;
    overflow-y: auto;
    position: relative;
  }
  
  .subscription-summary-count {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  
  .subscription-summary-title {
    color: #AEB9E1;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin: 2vh 0;
    gap: 4px;

  }
  
  .subscription-count {
    color: var(--Neutral-Colors-100, #FFF);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "DM Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }
  
  .subscription-growth {
    display: flex;
    padding: 2px 4px;
    align-items: center;
    gap: 5px;
    border-radius: 2px;
    border: 0.6px solid;
    background: rgba(5, 193, 104, 0.20);
    color: #14CA74;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  
  .subscription-growth-positive {
    border-color: rgba(5, 193, 104, 0.20);
    background: rgba(5, 193, 104, 0.20);
    color: #14CA74;
  }
  
  .subscription-growth-negative {
    border-color: rgba(255, 0, 0, 0.20);
    background: rgba(255, 0, 0, 0.20);
    color: #FF0000;
  }
  
  .subscription-growth-null {
    border-color: rgba(255, 255, 0, 0.20);
    background: rgba(255, 255, 0, 0.20);
    color: #FFFF00;
  }
  
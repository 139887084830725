.coming-soon-h1 {
  font-family: var(--dmsans);
  color: var(--white);
  font-size: 45px;
  font-weight: 800;
}
.comingsoon-container p {
  font-family: var(--montserrat);
  color: var(--white);
  font-size: 20px;
  text-align: center;
}
.comingsoon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  gap: 25px;
  padding: 5%;
}
.comingsoon-bg {
  position: absolute;
  z-index: -1;
  opacity: 0.5;
  bottom: 0;
}

.waitlist-container {
    cursor: pointer; /* Pointer cursor for clickability */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .waitlist-container:hover {
    transform: translateY(-5px); /* Slight upward movement on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  }
  
  .waitlist-box {
    border-radius: 8px;
    border: 0.6px solid var(--Secondary-Colors-Color-4, #343B4F);
    background: var(--Primary-Colors-Color-1, #1B263B); /* Slightly different background color */
    padding: 0 20px 20px;
    width: 300px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 300px;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease; /* Smooth background change */
  }
  
  .waitlist-container:active .waitlist-box {
    background: var(--Primary-Colors-Color-2, #28344D); /* Darker background on click */
  }
  
  .waitlist-summary-title {
    color: #AEB9E1;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    gap: 4px;
    margin: 2vh 0;
  }
  
  .waitlist-summary-count {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  
  .waitlist-count {
    color: var(--Neutral-Colors-100, #FFF);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "DM Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
  }
  
  .waitlist-growth {
    display: flex;
    padding: 2px 4px;
    align-items: center;
    gap: 5px;
    border-radius: 2px;
    border: 0.6px solid;
    background: rgba(5, 193, 104, 0.20);
    color: #14CA74;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 140% */
    transition: background 0.3s ease, color 0.3s ease;
  }
  
  .waitlist-growth-positive {
    border-color: rgba(5, 193, 104, 0.20);
    background: rgba(5, 193, 104, 0.20);
    color: #14CA74;
    }
  
  .waitlist-growth-negative {
    border-color: rgba(255, 0, 0, 0.20);
    background: rgba(255, 0, 0, 0.20);
    color: #FF0000;
    }
  
  .waitlist-growth-null {
    border-color: rgba(255, 255, 0, 0.20);
    background: rgba(255, 255, 0, 0.20);
    color: #FFFF00;
    }
  